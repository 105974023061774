.form_holder .forms_container {
    max-width: 1600px;
    margin: 0 auto;
    min-height: calc(100vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em;
}

.login_form_container form,
.signup_form_container form,
.reser_Password_form_container form {
    padding: 2em;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    background-color: rgba(73, 73, 73, 0.266);
    color: rgb(216, 216, 216);
    transition: 1s;
}

.signup_form_container form>div {
    width: 100%;
}

.signup_form_container form .accountType {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    justify-content: space-between;
}

.signup_form_container form .CreateAccountInputs {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.signup_form_container form .subscription_plans {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.signup_form_container form .subscription_plans .plan_group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.regular_plans,
.diamond_plans,
.supreme_plans {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.isHidden {
    display: none;
    /* hide radio buttons */
}

.label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    border: 2px solid gray;
    border-radius: 0.4em;
    transition: 0.6s;
}

.label:hover {
    border: 2px solid var(--secondary-color);
}

.radio:checked+.label {
    /* target next sibling (+) label */
    border: 2px solid var(--secondary-color);
}

.login_form_container form h4,
.signup_form_container form h4 {
    display: inline-flex;
    align-items: center;
    gap: 0.6em;
}

.input {
    width: 100%;
    padding: 1em;
    outline: none;
    border: none;
    background-color: transparent;
    border-radius: 0.4em;
    font-weight: 600;
    border: 2px solid gray;
    color: aliceblue;

}

.input:focus {
    border: 2px solid var(--secondary-color);
}

.submit_btn {
    padding: 0.6em;
    outline: none;
    border: none;
    background-color: transparent;
    border-radius: 4rem;
    background-color: var(--secondary-color);
    color: black;
    font-size: medium;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
}

.login_form_container form label,
.signup_form_container label {
    font-size: small;
    font-weight: 600;
    cursor: pointer;
}

@media only screen and (max-width: 800px) {
    .form_holder .forms_container {
        padding: 1em;
    }

    .signup_form_container form {
        width: 100%;
    }
}