.cPanel-wrapper {
    width: 100%;
    min-height: 100vh;
}

.cPanel {
    max-width: 1600px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
}

/* Sidebar */
.cPanel .sidebar {
    width: 250px;
    height: 100%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 1em 1em 0;
    overflow-y: auto;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 62px;
}

/* Top Sidebar */
.cPanel .sidebar .sidebar-top {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1em;
}

.cPanel .sidebar .sidebar-top img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover;
    border: 8px solid var(--primary-color);
    cursor: pointer;
    outline: none;
    border: none;
}

.cPanel .sidebar .sidebar-top label {
    font-weight: 600;
    font-size: large;
}

/* Bottom Sidebar */
.cPanel .sidebar .sidebar-btm {
    margin-top: 2em;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1em;
    width: 100%;
}

.cPanel .sidebar .sidebar-btm .dashboard-links {
    width: 100%;
    list-style: none;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.cPanel .sidebar .sidebar-btm .dashboard-links .pane-link {
    color: var(--secondary-color);
    background: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
    line-height: 3.4em;
    padding: 0 1em;
    border-radius: 4em;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.cPanel .sidebar .sidebar-btm .dashboard-links li:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.active-pane-link {
    color: black;
    background: transparent;
    font-size: 14px;
    font-weight: 600;
    line-height: 3.4em;
    padding: 0 1.6em;
    border-radius: 4em;
    border: 2px solid black;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

.highlight-link {
    outline: none;
    border: none;
    color: aliceblue;
    background-color: var(--accent);
    font-size: 14px;
    font-weight: 600;
    line-height: 3.4em;
    padding: 0 1.6em;
    border-radius: 4em;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}

/* cPanel-dashboard */
.cPanel-dashboard {
    width: calc(100% - 250px);
    height: 100%;
    padding: 0 2em;
}

/* Basic Info */
.panel-heading {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    color: var(--secondary-color);
}

.panel-heading button {
    outline: none;
    border: none;
    padding: 0.6em 1em;
    background-color: var(--secondary-color);
    border-radius: 0.6em;
    font-size: medium;
    font-weight: 600;
    cursor: pointer;
}

.grid-layout {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 1em;
    grid-row-gap: 1em;
    margin-top: 2em;
}

.grid-layout .grid-div {
    width: 100%;
    background: #00000064;
    border-radius: 0.6em;
}

.grid-layout .grid-div table {
    width: 100%;
    color: rgb(185, 185, 185);
    padding: 0.6em;
}

.grid-layout .grid-div table thead th {
    text-align: left;
    color: var(--secondary-color);
    line-height: 2em;
}

.grid-layout .grid-div table thead th:nth-child(even) {
    text-align: left;
    text-align: end;
}

.grid-layout .grid-div table tr:nth-last-child(1) button {
    cursor: pointer;
    margin-top: 1em;
    outline: none;
    border: none;
    border-radius: 0.4em;
    font-size: small;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1em;
    /* background-color: var(--secondary-color); */
    display: inline-flex;
    align-items: center;
    gap: 0.6em;
}

.grid-layout .grid-div table tr td {
    text-transform: capitalize;
    line-height: 2.6em;
}

.grid-layout .grid-div table tr td i {
    cursor: pointer;
}

.grid-layout .grid-div table tr td:nth-child(odd) input {
    text-align: left;
    text-align: start;
    text-transform: capitalize;
}

.grid-layout .grid-div table tr td:nth-child(even) {
    text-align: end;
}

.grid-layout .grid-div table input {
    border: none;
    outline: none;
    color: rgb(185, 185, 185);
    background-color: transparent;
    text-align: end;
    height: auto;
    width: 150px;
    font-size: inherit;
    text-transform: capitalize;
}

.grid-layout .grid-div table select {
    color: rgb(185, 185, 185);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0;
    height: auto;
    width: auto;
    width: fit-content;
    direction: rtl;
    text-align: right;
    font-size: inherit;

}

.grid-layout .grid-div table select option {
    background-color: rgb(4, 4, 4);
}

.grid-layout .grid-div textarea {
    padding: 0.8em;
    width: 100%;
    text-align: justify;
    font-size: inherit;
    line-height: 1.6em;
    outline: none;
    border: none;
    border-radius: 1em;
    height: 100%;
    background-color: transparent;
    color: rgb(185, 185, 185);
}

/* Gallery */
.colm-flex {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.gallery .grid-layout img {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

.gallery button {
    outline: none;
    border: none;
    background-color: var(--secondary-color);
    padding: 1em;
    border-radius: 0.6em;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.gallery video {
    width: 100%;
    object-fit: cover;
    border-radius: 1em;
}

.preview_photos {
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1em;
}

.preview_photos .photo_holder {
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 1em;
    cursor: pointer;
}

.preview_photos .photo_holder:hover .photo_actions {
    opacity: 1;
}

.preview_photos .photo_holder img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1em;
}

.preview_photos .photo_holder .photo_actions {
    transition: 0.4s ease-in-out;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1em;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.516);
    border-radius: 1em;
}

.preview_photos .photo_holder .photo_actions span {
    background: var(--accent);
    font-size: 0.6em;
    border-radius: 4rem;
    color: white;
    line-height: 3em;
    padding: 0 1em;
    font-weight: 600;
}

/* Affiliated Accounts */
.my-listings .affiliated-account-form {
    padding: 1em;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1.6em;
}

.my-listings .affiliated-account-form input {
    border: 2px solid var(--secondary-color);
    outline: none;
    padding: 0.8em 0.6em;
    border-radius: 0.6rem;
    background-color: transparent;
    color: white;
    font-weight: 400;
    font-size: inherit;
}

.my-listings .affiliated-account-form button {
    border: none;
    outline: none;
    padding: 0.8em 0.6em;
    border-radius: 0.6rem;
    font-weight: 600;
    font-size: medium;
    text-transform: uppercase;
    background-color: var(--secondary-color);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 0.8em;
}

.my-listings .agency-listed-profiles {
    padding: 1em 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-row-gap: 1em;
}

.my-listings .agency-listed-profiles .listed-profile {
    width: 100%;
    height: 150px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 0.2em;
    cursor: pointer;
}

.my-listings .agency-listed-profiles .listed-profile img {
    height: 100px;
    width: 100px;
    clip-path: circle(50%);
    border-radius: 50%;
    object-fit: cover;
}

.my-listings .agency-listed-profiles .listed-profile label {
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    gap: 0.4em;
    cursor: pointer;
}

.my-listings .agency-listed-profiles .listed-profile label[for='listed-profile-name'] {
    color: rgba(245, 245, 245, 0.715);
}

.grid-layout img {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

@media only screen and (max-width: 800px) {
    .cPanel {
        padding: 1em;
    }

    .cPanel .sidebar {
        border-radius: 1em;
    }

    .cPanel .sidebar,
    .cPanel-dashboard {
        width: 100%;
        position: unset;
    }

    .grid-layout {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    .cPanel-dashboard {
        padding: 2em 0;
    }

    /* Basic Info */
    .panel-heading {
        font-size: medium;
    }

    .panel-heading button {
        font-size: small;
        font-weight: 600;
    }

    .preview_photos {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    .preview_photos .photo_holder {
        position: relative;
        width: 100%;
        height: 400px;
        border-radius: 1em;
        cursor: pointer;
    }

    .grid-layout .grid-div table tr td:nth-child(odd) i {
        display: none;
    }
}