.footer-banner {
    max-width: 1600px;
    margin: 0 auto;
}

footer .footer-banner .content {
    padding: 1em;
}

footer .footer-banner .content h4 {
    line-height: 2.6em;
}

footer .footer-banner .content p {
    font-size: small;
    line-height: 1.6em;
    text-align: justify;
}

footer .footer-sitemap .footer-grid {
    max-width: 1600px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

footer .footer-sitemap .footer-grid .footer-grid-item {
    padding: 1em;
}

footer .footer-sitemap .footer-grid .footer-grid-item:nth-child(1) p {
    font-size: small;
    line-height: 1.4em;
    font-weight: 300;
    text-align: left;
}

footer .footer-sitemap .footer-grid .footer-grid-item:nth-child(2) table {
    margin-top: 0.5em;
    width: 100%;
}

footer .footer-sitemap .footer-grid .footer-grid-item:nth-child(2) table tr td {
    width: 50%;
}

footer .footer-sitemap .footer-grid .footer-grid-item:nth-child(2) table tr td a {
    line-height: 2em;
    font-size: small;
}

footer .footer-sitemap .footer-grid .footer-grid-item:nth-child(2) table tr td:nth-child(even) {
    text-align: end;
}

footer .footer-sitemap .footer-grid .footer-grid-item:nth-child(3) .social-links {
    margin-top: 1em;
    display: flex;
    align-items: center;
    gap: 2em;
}

footer .footer-sitemap .footer-grid .footer-grid-item:nth-child(3) .social-links a {
    color: white;
    font-size: x-large;
}

footer .footer-sitemap .footer-grid .footer-grid-item:nth-child(3) .social-links a:hover {
    transform: scale(1.4);
    transition: 0.4s ease-in-out;
    color: var(--accent);
}

hr {
    margin: 0 auto;
    width: 90%;
    border: 1px solid rgba(182, 182, 182, 0.137);
}

footer .footer-sitemap .description {
    font-size: small;
    line-height: 2em;
    padding: 0 1em 1em 1em;
    text-align: justify;
}

@media only screen and (max-width: 800px) {
    footer .footer-banner .content {
        padding: 0.6em;
    }

    footer .footer-sitemap .description {
        padding: 0.6em;
    }
}