.details-wrapper {
    max-width: 1600px;
    margin: 0 auto;
}

.details-grid {
    width: 100%;
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}

.details-grid .photo-gallery {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.details-grid .photo-gallery .slideshow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 100px;
    z-index: 0;
}

.details-grid .photo-gallery .slideshow .slides {
    width: 100%;
    position: relative;
    background: #ffffff;
    border-radius: 1em;
}

.details-grid .photo-gallery .slideshow .slides img {
    width: 100%;
    height: 500px;
    object-fit: contain;
    border-radius: 1em;
    outline: none;
    border: none;
}

/* Grid Item 2 */
.product-info {
    display: flex;
    flex-flow: column nowrap;
    gap: 1em;
    padding-top: 2em;
}

.product-info label[for="price"] {
    color: var(--secondary-color);
}

.description {
    color: aliceblue;
    text-align: justify;
    line-height: 1.6em;
    white-space: pre-line;
}

.product-info table tr td {
    line-height: 2em;
}

.qty {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.qty input,
.qty button {
    text-align: center;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    font-size: medium;
    font-weight: 600;
    cursor: pointer;
}

.qty input:disabled {
    background-color: white;
}

.qty button:first-child {
    border-radius: 0.4em 0 0 0.4em;
    background-color: var(--secondary-color);
}

.qty button:last-child {
    border-radius: 0 0.4em 0.4em 0;
    background-color: var(--secondary-color);
}

.checkout-actions {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    gap: 2em;
    padding: 1em 0;
}

.checkout-actions button {
    outline: none;
    border: none;
    padding: 0.6em 1em;
    font-size: medium;
    text-transform: uppercase;
    border-radius: 0.4em;
    font-weight: 600;
    cursor: pointer;
    transition: 0.4s ease-in;
    position: relative;
}

.checkout-actions button:nth-child(1) {
    background-color: var(--secondary-color);
}

.checkout-actions button:nth-child(2) {
    background-color: transparent;
    border: 2px solid var(--secondary-color);
    color: white;
}

.checkout-actions button span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.4em;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--accent);
    color: white;
}

@media only screen and (max-width: 900px) {
    .details-grid {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        grid-gap: 0;
        padding-top: 1em;
    }

    .details-grid .photo-gallery .slideshow {
        width: 100%;
    }

    .details-grid .photo-gallery .slideshow .slides img {
        width: 100%;
        height: 350px;
    }

    .checkout-actions {
        gap: 0.6em;
    }

    .checkout-actions button {
        width: 100%;
    }
}