.services {
    max-width: 1600px;
    margin: 0 auto;
}

.service-wrapper {
    padding: 1em;
    width: 100%;
    /* min-height: 100vh; */
    /* background: #000; */
}

.photoshoot-service {
    width: 100%;
    height: 100%;
    /* border: 2px solid tomato; */
}

.photoshoot-grid {
    width: 100%;
    height: 100%;
    /* border: 2px solid teal; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    grid-row-gap: 2em;
}

.photoshoot-grid-item {
    width: 100%;
    min-height: calc(100vh - 62px);
    /* border: 2px solid crimson; */
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
}

.photoshoot-grid-item h1,
.photoshoot-grid-item h2 {
    font-size: 4.4em;
    text-transform: uppercase;
}

.photoshoot-grid-item p {
    color: gray;
    text-align: justify;
    line-height: 1.8em;
}

.photoshoot-grid-item ul {
    color: gray;
    line-height: 1.8em;
    list-style-type: none;
}

.photoshoot-grid-item img {
    width: 100%;
    max-height: 700px;
    object-fit: contain;
}

/* Responsive styles */
@media only screen and (max-width: 980px) {
    .photoshoot-grid-item {
        min-height: 70vh;
    }

    .photoshoot-grid {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    .photoshoot-grid-item img {
        object-fit: cover;
    }

    .photoshoot-grid-item h1,
    .photoshoot-grid-item h2 {
        font-size: 3em;
        text-transform: uppercase;
    }
}