/* Container for the pricing table */
.pricing-table {
  width: 100%;
  min-height: calc(100vh - 61px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
}

/* Individual pricing cards */
.pricing-card {
  padding: 2em 0;
  background-color: rgba(73, 73, 73, 0.266);
  width: 300px;
  margin: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  border-radius: 1em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.pricing-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transform: translateY(-10px);
}

/* Heading */
.pricing-card h3 {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.4em;
  color: rgb(224, 224, 224);
  font-size: 18px;
  margin: 0px 0 20px 0;
}

/* Price */
.pricing-card .price {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--secondary-color);
}

.pricing-card .price sub {
  font-size: 16px;
}

/* Features list */
.pricing-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.pricing-card ul li {
  padding: 20px;
  border-top: 1px solid #e0e0e03a;
  font-size: 14px;
  color: rgb(169, 169, 169);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Call to action button */
.pricing-card button {
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
}

.pricing-card button:hover {
  background-color: transparent;
  color: aliceblue;
  border: 2px solid var(--secondary-color);
}

/* Media query to adjust styles for smaller screens */
@media (max-width: 991px) {
  .pricing-card {
    width: 100%;
  }
}