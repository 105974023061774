header {
    width: 100%;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--primary-color);
}

header .header-items {
    padding: 0.50em 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

}

.logo img {
    width: 40px;
}

.logo a {
    display: inline-flex;
    align-items: center;
    gap: 0.8em;
}

.logo a span {
    font-family: 'Kaushan Script', cursive;
    font-size: larger;
}

.nav-menu {
    list-style-type: none;
    display: flex;
    gap: 2em;
}

ul li a {
    padding: 0.5em 1em;
    font-size: small;
    font-weight: 500;
    border-radius: 6em;
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
}

.header-items .toogle-menu {
    display: none;
    color: white;
    font-size: xx-large;
}

.header-items .toogle-menu i {
    padding-left: 0.4em;
}

.mobile-menu {
    padding: 1em 0.4rem;
    order: 3;
    width: 100%;
    height: 100vh;
    display: inline-flex;
    flex-direction: column;
    gap: 1.6rem;
    list-style-type: none;
}

@media only screen and (max-width: 780px) {
    header .header-items {
        flex-direction: column;
    }

    .logo img {
        width: 30px;
    }

    .logo a span {
        font-size: medium;
    }
}

@media only screen and (max-width: 620px) {
    header .header-items {
        flex-direction: row;
    }

    .nav-menu {
        display: none;
    }

    .header-items .toogle-menu {
        display: unset;
    }

    ul li a {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 1em 0;
    }

    ul li a i {
        font-size: x-large;
    }
}