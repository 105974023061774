.apply-filters label[for="applyFilter"] {
    font-weight: 600;

}

.apply-filters .filters {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 2em;
    overflow-x: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.apply-filters .filters::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, and Opera */
}

.apply-filters .filters select {
    outline: none;
    color: rgb(200, 200, 200);
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 2px solid rgb(155, 155, 155);
    line-height: 2em;
    border-radius: 6px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0 1em;
    height: auto;
    width: auto;
    width: fit-content;
    direction: rtl;
    text-align: right;
    font-size: small;
    cursor: pointer;
}

.apply-filters .filters select:hover {
    border: 2px solid var(--secondary-color);
}

.apply-filters .filters select option {
    background-color: rgb(4, 4, 4);
}