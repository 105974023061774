.wrapper {
    max-width: 1600px;
    margin: 0 auto;
}

.Slider-Template .wrapper h4,
.Grid-Template .wrapper h4 {
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
}

.Slider-Template .wrapper p,
.Grid-Template .wrapper p {
    font-weight: 300;
    line-height: 1.6em;
    text-align: justify;
}

.Slider-Template .wrapper .flexbox {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    overflow-x: auto;
    gap: 2em;
    scroll-behavior: smooth;
    padding: 12px 4px 12px 4px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.Slider-Template .wrapper .flexbox::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.Slider-Template .wrapper .flexbox {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.Slider-Template .wrapper .flexbox .flex-item {
    width: 250px;
    min-width: 250px;
}

.Slider-Template .wrapper .flexbox .flex-item .card {
    border: 4px solid var(--secondary-color);
}

.Grid-Template .wrapper .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 2em;
    grid-row-gap: 2em;
}

@media only screen and (max-width: 800px) {
    .Grid-Template .wrapper .grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 0.4em;
        row-gap: 1em;
    }

    .Grid-Template .wrapper .grid .card {
        border-radius: 0.4em;
    }

    .Grid-Template .wrapper .grid .card .upper-card img {
        border-radius: 0.4em 0.4em 0 0;
    }

    .Grid-Template .wrapper .grid .card .upper-card label[for="badge"] {
        top: 6px;
        right: 6px;
    }

    .Slider-Template .wrapper h4,
    .Grid-Template .wrapper h4 {
        font-size: small;
    }

    .Slider-Template .wrapper p,
    .Grid-Template .wrapper p {
        font-size: small;
    }
}