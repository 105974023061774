.earn-rewards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1em;
    grid-row-gap: 1em;
    overflow: hidden;
}

.coupon-card {
    color: #fff;
    text-align: center;
    border-radius: 15px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    /* overflow: hidden; */
}

.coupon-card:hover {
    transform: scale(0.9);
}

.circle1,
.circle2 {
    background: var(--primary-color);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

}

.circle1 {
    left: -25px;
}

.circle2 {
    right: -25px;
}

.coupon-card .coupon-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
}

.coupon-card .coupon-content .coupon-details {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.4em;
}

.coupon-card .coupon-content .coupon-details h2 {
    text-transform: capitalize;
    line-height: 1.6em;
    font-size: medium;
}

.coupon-card .coupon-content .coupon-details .call-to-action {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 16px auto;
    width: fit-content;
    font-weight: 600;
}

.coupon-card .coupon-content .coupon-details .call-to-action .coupon-code {
    border: 1px dashed #fff;
    padding: 10px 20px;
    text-transform: uppercase;
    /* border-right: 0; */
}

.coupon-card .coupon-content .coupon-details .call-to-action .coupon-btn {
    border: 1px solid #fff;
    background: #fff;
    padding: 10px 20px;
    color: black;
    cursor: pointer;
}

.coupon-card .coupon-content .coupon-details p {
    font-weight: 600;
}

.coupon-card .coupon-content .coupon-details span {
    font-size: small;
    cursor: pointer;
}

.coupon-card .coupon-content .coupon-details .expired {
    position: absolute;
    bottom: 1em;
    right: 1em;
    width: max-content;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: medium;
    padding: 0.2em;
    font-size: small;
    border-radius: 0.4em;
    font-weight: 500;
}

.coupon-card .coupon-content .coupon-details .delete-coupon {
    position: absolute;
    bottom: 1em;
    left: 1em;
    width: max-content;
    background: #fff;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: medium;
    color: red;
}

@media only screen and (max-width: 800px) {
    .earn-rewards-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}