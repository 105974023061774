.terms {
    padding: 2em;
}

.terms p,
.terms ul li {
    text-align: justify;
    font-size: small;
    color: rgb(77, 77, 77);
    line-height: 2em;
}

.terms ul li a {
    color: rgb(77, 77, 77);
}

.terms ul {
    padding: 1em 2em;
}

.terms h1,
.terms h2,
.terms h3 {
    margin: 1em 0;
}