:root {
    --glow: rgba(255, 195, 26, 0.4);
}

section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;
}

#cube {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 300ms;
    animation: hover 0.6s ease-in-out infinite alternate;
    animation-play-state: running;
    user-select: none;
}

.hexagon {
    transform: scale(1.8);
    z-index: -2;
    position: relative;
    width: 160px;
    height: 92.38px;
    background-color: var(--glow);
    margin: 46.19px 0;
    filter: blur(20px);
}

.hexagon:before,
.hexagon:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
}

.hexagon:before {
    bottom: 100%;
    border-bottom: 46.19px solid var(--glow);
}

.hexagon:after {
    top: 100%;
    width: 0;
    border-top: 46.19px solid var(--glow);
}

.cube {
    position: absolute;
    height: 80%;
    width: 80%;
}

.back {
    background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxBackground_2x_b2espr.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}

.top {
    background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxTopFlap_2x_f9cb8g.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
}

.left {
    background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxLeftFlap_2x_y8u4gz.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
}

.right {
    background-image: url("https://res.cloudinary.com/dbrwtwlwl/image/upload/v1580369339/cube/mysteryBoxRightFlap_2x_abexhh.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
}

.powerup {
    background-size: cover;
    border-radius: 50%;
    z-index: 100;
    overflow: hidden;
    height: 48px;
    width: 48px;
    z-index: -5;
    position: absolute;

}

section #message {
    cursor: pointer;
    transition: transform 300ms;
    animation: hover 0.6s ease-in-out infinite alternate;
    animation-play-state: running;
    user-select: none;
    text-align: center;
    font-size: x-large;
    font-weight: 800;
}

section #claim {
    display: none;
    z-index: 4;
}

@keyframes hover {
    from {
        transform: translateY(-0.8rem);
    }

    to {
        transform: translateY(0.8rem);
    }
}

@media only screen and (max-width: 800px) {
    section {
        height: 60vh;
    }

    #cube {
        height: 90%;
        width: 90%;
    }
}