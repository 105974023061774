.shop {
    width: 100%;
}

.shop .shop-wrapper {
    padding: 1em;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    gap: 1em;
}

.shop .shop-wrapper h2 {
    display: inline-flex;
    gap: 0.4em;
}


.products-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1.2em;
    grid-row-gap: 2.4em;
}

.products-grid .product-card {
    width: 100%;
    min-height: 260px;
    border-radius: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.4em;
    align-items: center;
    transition: 0.3s ease-in-out;
}

.products-grid .product-card:hover {
    transform: scale(0.9);
}

.products-grid .product-card img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    border-radius: 1em 1em 0 0;
    background-color: white;
}

.products-grid .product-card .product-info {
    width: 100%;
    padding: 1em 0.4em;
    display: flex;
    flex-flow: column nowrap;
    gap: 0.4em;
    cursor: pointer;
}

.products-grid .product-card label:first-child {
    color: whitesmoke;
    height: 34px;
}

.products-grid .product-card label {
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* text-align: center; */
    font-size: small;
}

.products-grid .product-card label:last-child {
    font-weight: 600;
    text-align: center;
}


@media only screen and (max-width: 800px) {

    .products-grid {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        grid-gap: 0.4em;
        grid-row-gap: 0.4em;
    }

    .products-grid .product-card {
        height: 210px;
        border-radius: 0.4em;
    }

    .products-grid .product-card img {
        width: 100%;
        height: 150px;
        border-radius: 0.4em 0.4em 0 0;
    }
}