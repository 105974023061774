.shopping_cart_container {
    max-width: 1600px;
    margin: 0 auto;
}

.shopping_cart {
    padding: 1em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    min-height: 90vh;
}

/* Products */
.display_cart_items {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.cart_item {
    display: flex;
    flex-direction: row;
    box-shadow: rgb(53, 53, 53) 0px 3px 8px;
    border-radius: 0.6em;
    cursor: pointer;
}

.cart_item img {
    width: 250px;
    height: 200px;
    object-fit: contain;
    border-radius: 0.6em 0 0 0.6em;
    background-color: white;
}

.cart_item .item_description {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 0.8em;
}

.cart_item .item_description label[for="item_name"] {
    color: white;
    font-size: larger;
    line-height: 1.4em;
}

.cart_item .item_description label[for="item_price"] {
    color: var(--secondary-color);
    font-weight: 600;
}

.cart_item .item_description>div {
    display: flex;
    justify-content: space-between;
}

.cart_item .item_description>div label[for="item_quantity"] {
    color: whitesmoke;
}

.cart_item .item_description>div label[for="delete_btn"] {
    /* width: 100px; */
    padding: 0 1em;
    line-height: 2.4em;
    background-color: crimson;
    color: whitesmoke;
    text-align: center;
    border-radius: 0.4em;
    font-weight: 500;
    cursor: pointer;
}

/* Checkout Details */
.checkout_details {
    padding-left: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.checkout_details .order_summary {
    border-radius: 0.6em;
    box-shadow: rgb(53, 53, 53) 0px 3px 8px;
    padding: 0.4em;
}

.checkout_details .order_summary table {
    width: 100%;
    color: whitesmoke;
    border-collapse: collapse;
}

.checkout_details .order_summary table th {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #242424;
}

.checkout_details .order_summary table td {
    padding: 8px;
    text-align: left;
}

@media only screen and (max-width: 800px) {
    .shopping_cart {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        row-gap: 1em;
    }

    .cart_item {
        flex-direction: column;
    }

    .cart_item img {
        width: 100%;
        height: 200px;
        object-fit: contain;
        border-radius: 0.6em 0.6em 0 0;
        background-color: white;
    }

    .checkout_details {
        padding: 0;
    }
}