.manage-products form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    padding: 2em;
}

.manage-products form {
    margin: 0 auto;
    width: 100%;
    background: #00000064;
    border-radius: 0.6em;
    align-items: flex-end;
}

.manage-products form input,
.manage-products form textarea {
    outline: none;
    border: 2px solid var(--secondary-color);
    padding: 0.6em 1em;
    border-radius: 0.4em;
    font-size: inherit;
    font-weight: 400;
    color: aliceblue;
    background-color: transparent;
    cursor: pointer;
}

.manage-products form input[type="text"],
.manage-products form textarea {
    width: 100%;
}

.manage-products form input[type="button"] {
    color: black;
    background-color: var(--secondary-color);
    text-transform: uppercase;
    font-weight: 600;
}

/* Manage Products Display Table */
.table-holder {
    max-width: 100%;
    overflow: auto;
}

.products-display-table {
    border: 1px solid var(--secondary-color);
    border-radius: 1em;
    border-collapse: collapse;
    height: 100%;
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
}

.products-display-table th,
.products-display-table td {
    text-align: left;
    padding: 8px;
    font-size: 14px;
}

.products-display-table th {
    background-color: var(--secondary-color);
    line-height: 2em;
}


.products-display-table td:nth-child(2) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.products-display-table td:last-child,
.products-display-table th:last-child {
    text-align: end;
}

.products-display-table tr:nth-child(even) {
    background-color: #00000064;
}

.products-display-table button {
    padding: 6px 12px;
    text-align: center;
    display: inline-block;
    font-size: 14px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 0.4em;
    transition: 0.2s ease-in;
    background-color: transparent;
    color: aliceblue;
    border: 2px solid var(--secondary-color);
    font-weight: 600;
}

.products-display-table button:hover {
    background-color: var(--secondary-color);
    color: black;
}

/* Manage Orders */
.order-details-table table {
    width: 100%;
}

.order-details-table tr td {
    padding: 0.4em;
    width: 50%;
}

.order-details-table tr td:nth-child(even) {
    text-align: end;
}

/* Upload & Select Product Photo Component */
.select-photos-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.select-photos-container .product-photos-gallery {
    width: 100%;
    height: 100%;
}

.select-photos-container .product-photos-gallery .photos-wrapper {
    padding: 16px 16px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1em;
    grid-row-gap: 1em;
    border: 2px solid var(--secondary-color);
}

.select-photos-container .product-photos-gallery .photos-wrapper .photo {
    width: 100%;
    height: 300px;
    position: relative;
    cursor: pointer;
}

.select-photos-container .product-photos-gallery .photos-wrapper .photo .photo-actions {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(35, 35, 35, 0.389);
    display: none;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    transition: 1s ease-in-out;
}

.select-photos-container .product-photos-gallery .photos-wrapper .photo:hover .photo-actions {
    display: flex;
    transition: 1s ease-in-out;
}

.select-photos-container .product-photos-gallery .photos-wrapper .photo .photo-actions button {
    outline: none;
    border: none;
    padding: 0.4em 0.8em;
    font-size: small;
    color: white;
    border-radius: 0.4em;
    background-color: var(--accent);
    cursor: pointer;
    font-weight: 600;
}

.select-photos-container .product-photos-gallery .photos-wrapper .photo label {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
}

.select-photos-container .product-photos-gallery .photos-wrapper .photo img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
    border-radius: 0.4em;
    background-color: white;
}

.select-photos-container .product-photos-gallery .photos-wrapper .photo .checkbox:checked+label::before {
    content: "✓";
    position: absolute;
    top: -16px;
    left: -16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--secondary-color);
    z-index: 1;
    transform: scale(1);
}

.select-photos-container .product-photos-gallery .photos-wrapper .photo .checkbox:checked+label {
    border: 4px solid var(--secondary-color);
}

/* Order Status */
.progress-bar {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: whitesmoke;
    /* border-radius: 10px; */
}

.step {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 10px;
    font-weight: bold;
    color: black;
}

.step--placed {
    left: calc(25% - 25px);
}

.step--packed {
    left: calc(50% - 25px);
}

.step--shipped {
    left: calc(75% - 25px);
}

.step--delivered {
    right: 0;
}

.progress-bar::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--secondary-color);
    /* border-radius: 10px; */
    animation: fill-progress 5s ease-in-out forwards;
}

.progress-bar--placed::before {
    animation: fill-progress-placed 1.25s ease-in-out forwards;
}

.progress-bar--packed::before {
    animation: fill-progress-packed 2.5s ease-in-out forwards;
}

.progress-bar--shipped::before {
    animation: fill-progress-shipped 3.75s ease-in-out forwards;
}

.progress-bar--delivered::before {
    animation: fill-progress-delivered 5s ease-in-out forwards;
}

/* Coupons/Rewards Section */
.create-reward-form {
    margin: 0 auto;
    padding: 2em 1.4em;
    width: 80%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 2em;
    background-color: rgba(37, 37, 37, 0.435);
    border-radius: 1em;
}

.create-reward-form input {
    color: aliceblue;
    width: 100%;
    padding: 1em;
    font-size: small;
    outline: none;
    border: none;
    background-color: transparent;
    border-radius: 1em;
    border: 2px solid var(--secondary-color);
    font-weight: 600;
}

.create-reward-form button {
    outline: none;
    background-color: var(--secondary-color);
    padding: 0.8em 2em;
    border-radius: 0.4em;
    font-size: small;
    font-weight: 600;
    cursor: pointer;
    border: 2px solid var(--secondary-color);
    transition: 0.3s ease-in-out;
    display: inline-flex;
    align-items: center;
    gap: 0.6em;
}

.create-reward-form button:hover {
    background-color: transparent;
    color: aliceblue;
}

/* Mangage User Profiles */
.magage-profiles-container .display-profiles {
    width: 100%;
    overflow: auto;
}

.magage-profiles-container .display-profiles table {
    border: 1px solid var(--secondary-color);
    border-radius: 1em;
    border-collapse: collapse;
    height: 100%;
    width: 100%;
    /* max-width: 100%; */
    overflow-x: auto;
}

.magage-profiles-container .display-profiles table th,
.magage-profiles-container .display-profiles table td {
    text-align: left;
    padding: 8px;
    font-size: 14px;
}

.magage-profiles-container .display-profiles table td:nth-child(2) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.magage-profiles-container .display-profiles table td:last-child,
.magage-profiles-container .display-profiles table th:last-child {
    text-align: end;
}

.magage-profiles-container .display-profiles table tr:nth-child(even) {
    background-color: #00000064;
}

.magage-profiles-container .display-profiles table button {
    /* width: 80%; */
    padding: 6px;
    text-align: center;
    font-size: 14px;
    margin: 8px 2px;
    cursor: pointer;
    border-radius: 0.4em;
    transition: 0.2s ease-in;
    background-color: transparent;
    color: aliceblue;
    border: 2px solid var(--secondary-color);
    font-weight: 600;
}

.magage-profiles-container .display-profiles table button:hover {
    background-color: var(--secondary-color);
    color: black;
}


@keyframes fill-progress {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes fill-progress-placed {
    0% {
        width: 0%;
    }

    100% {
        width: 25%;
    }
}

@keyframes fill-progress-packed {
    0% {
        width: 25%;
    }

    100% {
        width: 50%;
    }
}

@keyframes fill-progress-shipped {
    0% {
        width: 50%;
    }

    100% {
        width: 75%;
    }
}

@keyframes fill-progress-delivered {
    0% {
        width: 75%;
    }

    100% {
        width: 100%;
    }
}

.set-status-btn {
    padding: 1em;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    /* justify-content: center; */
    gap: 1em;
}

.set-status-btn label {
    font-size: small;
    font-weight: 600;
    cursor: pointer;
    color: white;
}

/* Responsive styles */
@media only screen and (max-width: 800px) {
    .create-reward-form {
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .manage-products h1 {
        font-size: medium;
    }

    .manage-products form {
        padding: 0.6em;
    }

    .products-display-table table {
        font-size: 12px;
    }

    .products-display-table th,
    .products-display-table td {
        padding: 6px;
    }

    .products-display-table button {
        width: 100%;
        display: block;
        font-size: 12px;
        padding: 4px 8px;
    }

    /* Manage Orders */
    .manage-orders ul {
        font-size: small;
    }

    .manage-orders ul li i {
        display: none;
    }

    .order-details-table tr td {
        padding: 2em 0.2em;
    }
}