* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  user-select: none;
}

body {
  height: 100%;
  scroll-behavior: smooth;
}

body.popup-open {
  overflow: hidden;
}

:root {
  --primary-color: #101820FF;
  --secondary-color: rgb(234, 159, 61);
  --accent: #F42156;
}

/* Topography */
.primary-text {
  color: var(--primary-color);
  display: inline-flex;
  align-items: center;
  gap: 0.8em;
}

.secondary-text {
  color: var(--secondary-color);
  display: inline-flex;
  align-items: center;
  gap: 0.8em;
}

.accent-text {
  color: var(--accent);
  display: inline-flex;
  align-items: center;
  gap: 0.8em;
}

.white-text {
  color: rgb(220, 220, 220);
}

.gray-text {
  color: rgba(175, 175, 175, 0.856);
}


/* Class */
.primary-bg {
  background-color: var(--primary-color);
}

.secondary-bg {
  background-color: var(--secondary-color);
}

.link {
  text-decoration: none;
}

.active {
  color: var(--secondary-color);
}

.txt-center {
  text-align: center;
}

.txt-justify {
  text-align: justify;
}

/* Buttons */
.primary-btn {
  border-radius: 6em;
  width: max-content;
  outline: none;
  border: none;
  padding: 0.8em 1.4em;
  font-weight: 600;
  font-size: medium;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 0.6em;
  cursor: pointer;
}

.diamond-icon {
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./img/diamond-icon.png');
}

.supreme-icon {
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./img/supreme-icon.png');
}

.basic-icon {
  width: 32px;
  height: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./img/basic-icon.png');
}

.agency-icon {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('./img/agency-icon.png');
  background-size: contain;
}

.profile-verified {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-image: url('./img/profile-verified.png');
  background-size: contain;
  background-color: var(--primary-color);
  border-radius: 50%;
}

.crown-icon {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('./img/crown.png');
  background-size: contain;
}

.diamond {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('./img/diamond.png');
  background-size: contain;
}

.active-account-btn {
  outline: none;
  border: none;
  color: white;
  background: var(--accent);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.8em;
  padding: 0 1.6em;
  border-radius: 4em;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.inactive-account-btn {
  outline: none;
  border: none;
  color: white;
  background: red;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 2.8em;
  padding: 0 1.6em;
  border-radius: 4em;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.online {
  color: rgb(12, 170, 12);
  display: inline-flex;
  align-items: center;
  gap: 0.4em;
}

.offline {
  color: crimson;
}

.hidden {
  display: none;
}

.visible {
  animation: fadeIn 0.6s linear;
  display: block;
}

.x-light-txt {
  color: rgb(95, 95, 95);
}

.xx-light-txt {
  color: rgba(175, 175, 175, 0.856);
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
}

@media only screen and (max-width: 980px) {
  .primary-btn {
    padding: 0.8em 1.4em;
    font-weight: 600;
    font-size: smaller;

  }
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1;
  }
}

/* ---------------------------- */