.user-profile-layout {
    max-width: 1600px;
    padding: 1em;
    margin: 0 auto;
}

.profile-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}

/* Slideshow */
/* Grid Item 1 */

.profile-grid .photo-gallery {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.profile-grid .photo-gallery .slideshow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    margin: 0 auto;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 100px;
    z-index: 0;
}

.profile-grid .photo-gallery .slideshow .slides {
    width: 100%;
    position: relative;
}

.slides img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    border-radius: 1em;

}

label[For="imageNumberText"] {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    font-weight: 600;
    font-size: 12px;
    background-color: black;
    padding: 0.2em 0.5em;
    text-align: center;
    border-radius: 1em;
}

.prev {
    left: 0;
    border-radius: 3px 0 0 3px;
}

.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

.prev:hover,
.next:hover {
    background-color: rgba(255, 255, 255, 0.8);
    color: rgba(0, 0, 0, 0.8);
}

.dot {
    cursor: pointer;
    height: 0.4em;
    width: 0.4em;
    margin: 0 2px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active_dot {
    scale: 1.4;
    transition: 0.4s ease-in-out linear;
}

/* -------------------------------------------------------- */

/* Section to display profile info */
/* Grid Item 2 */
.profile-details .info {
    display: flex;
    flex-direction: column;
    gap: 1.2em;
}

.profile-details .info h2 {
    display: inline-flex;
    align-items: center;
    gap: 0.4em;
    text-transform: capitalize;
}

.verifiedText {
    width: fit-content;
    font-size: small;
    background-color: #326ADE;
    color: white;
    font-weight: 500;
    padding: 0.2em 0.4em;
    border-radius: 0.4em;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.6em;
}

.unverifiedText {
    width: fit-content;
    font-size: small;
    background-color: #ef3f1b;
    color: white;
    font-weight: 500;
    padding: 0.2em 0.4em;
    border-radius: 0.4em;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.6em;
}

.profile-details .info ul {
    display: flex;
    gap: 1em;
}

.profile-details .info ul li {
    list-style-type: none;
    font-size: small;
}

.profile-details .info .major-tokens {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.4em;
}

.profile-details .info .major-tokens label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5em;
    padding: 1em 2em;
    border-radius: 0.4em;
    font-size: 0.8em;
    border: 2px solid var(--secondary-color);
}

.profile-details .info .major-tokens label .token-text {
    color: gray;
    text-transform: uppercase;
}

.profile-details .info .major-tokens label .token-value {
    font-weight: 600;
    color: var(--secondary-color);
    font-size: medium;
}

.profile-details .info .minor-tokens {
    display: flex;
    flex-flow: row wrap;
}

.profile-details .info .minor-tokens label {
    font-size: small;
    color: var(--white);
    background-color: var(--secondary-color);
    padding: 0.6em;
    border-radius: 0.4em;
    list-style-type: none;
    margin: 0.4em;
}

.profile-details .info p {
    text-align: justify;
    font-size: large;
    line-height: 1.8em;
}

.profile-details .info .contact {
    display: flex;
    flex-direction: column;
    gap: 0.8em;
}

.profile-details .info .contact h3 {
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
}

.profile-details .info .contact label[for="text"] {
    font-size: small;
    font-weight: 200;
    padding: 1em;
    background-color: #98989820;
    border-radius: 0.4em;
}

.profile-details .info .contact label span {
    color: var(--secondary-color);
    font-weight: 600;
}

.profile-details .info .contact>div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 2em;
}

.profile-details .info .contact label[for="mobileno"],
.profile-details .info .contact label[for="whatsapp"] {
    display: inline-flex;
    align-items: center;
    gap: 0.6em;
}

.profile-details .info table {
    width: 80%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 1em;
    border-radius: 0.4em;
    border: 2px solid var(--secondary-color);
}

.profile-details .info table thead {
    text-align: left;
}

.profile-details .info table tr td {
    line-height: 2em;
    font-size: small;
    text-transform: capitalize;
}

.display-video-profile {
    display: flex;
    flex-direction: column;
    object-fit: cover;
    gap: 1em;
}

.display-video-profile video {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 1em;
}

.profile-photos {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.profile-photos img {
    width: 100%;
    object-fit: contain;
    border-radius: 1em;
    cursor: pointer;
}

.blank_area {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.invalid-user-template {
    width: 100%;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.invalid-user-template img {
    width: 60%;
    height: auto;
}

@media only screen and (max-width: 900px) {
    .profile-grid {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        grid-gap: 0;
    }

    .profile-grid .photo-gallery .slideshow {
        width: 100%;
    }

    .profile-details .info h1 {
        font-size: x-large;
    }

    .profile-details .info .major-tokens label[for="token"] {
        font-size: 0.6em;
    }

    .profile-details .info table {
        width: 100%;
    }

    .display-video-profile video {
        width: 100%;
    }
}