.card {
    width: 100%;
    border-radius: 1em;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.card:hover {
    transform: scale(0.9);
}

.card .upper-card {
    width: 100%;
    height: 250px;
    position: relative;
}

.card .upper-card label[for="badge"] {
    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 1;
}

.card .upper-card label[for="online-status"] {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.879);
    padding: 0.4em 0.6em;
    font-size: small;
    /* font-weight: 500; */
    border-radius: 0.4em 0 0 0.4em;
    height: max-content;
}

.card .upper-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1em 1em 0 0;
    transition: transform 0.3s ease;
}

/* Lower Card */
.lower-card {
    display: flex;
    flex-direction: column;
    padding: 0.4em;
    gap: 0.4em;
}

.lower-card label[For="profile-name"] {
    text-align: center;
    line-height: 1.6em;
    font-weight: 600;
    text-transform: capitalize;
}

.lower-card .profile-details,
.lower-card .contact-info,
.lower-card .location-info {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.lower-card .profile-details label[For="price"] {
    font-weight: 600;
    font-size: small;
}

@media only screen and (max-width: 800px) {
    .card .upper-card label[for="online-status"] {
        font-size: x-small;
    }

    .lower-card .profile-details,
    .lower-card .contact-info,
    .lower-card .location-info {
        font-size: small;
    }
}